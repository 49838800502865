// import { useState, useEffect } from "react";
import Header from '../../components/header'
import Footer from '../../components/footer'
import MainContent from './socialMedia'
import { Container } from '../style';

import { } from "./style";

const Layout = () => {

	return (
		<Container>
			<Header />
			<MainContent />
			<Footer />
		</Container>
	)
}

export default Layout;