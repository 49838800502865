import styled from "styled-components";
import { Theme } from "../../utils";

export const FooterContainer = styled.div`
	width: 100%;
	padding-bottom: 77px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: radial-gradient(43.73% 70.86% at 52.79% 70.77%, #23272E 2.1%, #0B1014 84.11%)
`;

export const FooterTitle = styled.p`
	padding-top: 60px;
	background: radial-gradient(95% 95% at 36.4% 1.4%, #FFAF9B 0%, #FFE978 20.64%, rgba(255, 186, 255, 0.850701) 42.07%, #FFD5B7 62.26%, #FFFEE2 80.49%, #79A2F2 100%);
	-webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
	font-weight: 600;
	font-size: 64px;
	line-height: 72px;
	letter-spacing: 0.01em;

	@media screen and (max-width: 780px) {
		text-align: center;
		font-size: 30px;
	}
`;

export const IconContainer = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 780px) {
		text-align: center;
	}
`;

export const Icon = styled.div`
	margin-right: 40px;
	&:last-child {
		margin-right: 0;
	}

	&:nth-child(3) {
		a {
			div {
				margin-top: 8px !important;
			}
		}
	}

	a {
		text-decoration: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 780px) {
			img {
				width: 40px;
				height: 40px;
			}
		}
	}

	@media screen and (max-width: 780px) {
		margin-right: 12px;
	}
`;


export const LinkText = styled.div`
	margin-top: 19px;
	color: ${Theme.whiteText};
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.01em;

	@media screen and (max-width: 780px) {
		display: none;
	}
`;