import styled from "styled-components";
import { Theme } from "../../utils";

export const HeaderWrapper = styled.div`
	background-color: ${Theme.backgroundColor};
	padding: 34px 260px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/* z-index: 1; */

	@media screen and (max-width: 1200px) {
		width: 100%;
		height: 100%;
		position: relative;
	}

	@media screen and (max-width: 780px) {
		padding: 34px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const LeftContainer = styled.div`
	height: 100%;
`;

export const RoyaLogo = styled.a`
	img {
		width: 145px;
		height: 89px;
	}

	
`;

export const RightContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const ListYourProjects = styled.div`
	color: ${Theme.whiteText};
	margin-right: 32px;
`;

export const ConnectWalletBorder = styled.div`
	background: conic-gradient(
    from 180deg at 50% 50%,
    #ffffff -1.14deg,
    #fadd58 4.87deg,
    rgba(255, 255, 255, 0.964973) 12.61deg,
    #e6ae83 24.16deg,
    rgba(255, 255, 255, 0.949566) 29.95deg,
    rgba(255, 255, 255, 0.901443) 35.48deg,
    #ebbbf0 41.11deg,
    rgba(255, 255, 255, 0.856301) 51.73deg,
    #fadd58 59.78deg,
    rgba(255, 255, 255, 0.810395) 68.26deg,
    #cc6347 75.93deg,
    rgba(255, 255, 255, 0.765917) 88.06deg,
    #538aff 93.41deg,
    rgba(255, 255, 255, 0.7209) 96.09deg,
    #e4edff 107.05deg,
    rgba(255, 255, 255, 0.683293) 114.01deg,
    #e6ae83 120.88deg,
    #c4c4c4 122.02deg,
    rgba(255, 255, 255, 0.646412) 122.38deg,
    #103f5a 128.43deg,
    rgba(124, 200, 237, 0.579017) 134.06deg,
    rgba(39, 51, 57, 0.544723) 140.38deg,
    #242f34 146.53deg,
    rgba(33, 46, 52, 0.498667) 150.86deg,
    #23262b 316.22deg,
    #f6f08e 324.76deg,
    #b3b3b3 332.03deg,
    #ffffff 335.66deg,
    #6751f0 343.76deg,
    #e7e7e7 352.96deg,
    #ffffff 358.86deg,
    #fadd58 364.87deg
  );
  border-radius: ${Theme.componentBorderRadius};
  padding: 2px;
  width: 173px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  width: 201px;
  height: 52px;
`;

export const ConnectWallet = styled.div`
	background: linear-gradient(189deg, #2C2F35 6.83%, #0B1014 97.87%);
	border-radius: ${Theme.componentBorderRadius};
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${Theme.whiteText}
`;

export const MenuIconEl = styled.div`

`;
