import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
	Landing,
	SubmitGame,
	SocialMedia
} from "./pages";
import { Paths } from "./utils";

const App = () => {

	return (
		<Router>
			<Switch>
				<Route
					exact
					path={Paths.landing}
					component={Landing}
				/>
				<Route exact path={Paths.submitGame} component={SubmitGame} />
				<Route exact path={Paths.socialMedia} component={SocialMedia} />
			</Switch>
		</Router>
	);
};

export default App;
