require('dotenv').config();

export const baseURL = process.env.REACT_APP_SERVER_URL;

export const userEmail = process.env.REACT_APP_USER_EMIAL;

export const userPassword = process.env.REACT_APP_USER_PASSWORD;

export const gotoAppLink = process.env.REACT_APP_GOTO_APP_LINK;

export const configNetwork = "mainnet";

export const Paths = {
	landing: "/",
	submitGame: "/submit-game",
	socialMedia: "/social-media"
};

export const SocialLinkInfo = {
	twitter: "https://twitter.com/Royale_Finance",
	telegramOfficial: "https://t.me/Royalefinanceofficial",
	telegramAnnouncement: "https://t.me/RoyaleFinance",
	medium: "https://medium.com/@officialroyale",
	docs: "https://royalefinance.gitbook.io/royale-finance-docs/",
	mRoyaSwap: "https://staging.d2brf9r7tb77dk.amplifyapp.com/",
	coinGecko: "https://coingecko.com",
	coinMarketCap: "https://coinmarketcap.com"
};