import React from 'react';
import { useHistory } from "react-router-dom";

import { Paths, gotoAppLink, SocialLinkInfo } from "../../utils";

import {
	PageContainer,
	GoToHome,
	// HomeBg,
	// BottomLeft,
	// TopRight,
	LauncherContainer,
	ComingSoon,
	BtnContainer,
	GoToContainer,
	GoToApp,
	LinksContainer,
	SocialLinks,
	YourSubmitGame,
	Border
} from "./style";
import {
	RoyaLogoIcon,
	TwitterIcon,
	TelegramIcon,
	MediumIcon,
	// BottomLeftBg,
	// TopRightBg,
	VideoFile,
} from "../../images";

const Landing = () => {

	const history = useHistory();

	const handleSumitGame = (path) => {
		history.push(path);
	};

	return (
		<>
			<PageContainer>
				{/* <HomeBg>
					<BottomLeft>
						<img src={BottomLeftBg} alt="home-bg-img" />
					</BottomLeft>
					<TopRight>
						<img src={TopRightBg} alt="home-bg-img" />
					</TopRight>
				</HomeBg> */}
				<LauncherContainer>
					<GoToHome
						href='/'
					>
						<img src={RoyaLogoIcon.default} alt="royale logo" />
					</GoToHome>
					<ComingSoon>Gaming Launchpad <br /> Coming Soon</ComingSoon>
					{/* <ComingSoonText>
						Curabitur odio nisl, sollicitudin vel vulputate eget, luctus at nulla. Morbi iaculis lectus vel turpis hendrerit efficitur.
					</ComingSoonText> */}
					<BtnContainer>
						<Border>
							<YourSubmitGame onClick={() => handleSumitGame(Paths.submitGame)}>
								Submit Your Game
							</YourSubmitGame>
						</Border>
						<GoToContainer>
							<GoToApp
								href={gotoAppLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<span data-text="Go to App"><span>Go to App</span></span>
							</GoToApp>
						</GoToContainer>
					</BtnContainer>
				</LauncherContainer>
				<LinksContainer>
					<SocialLinks
						href={SocialLinkInfo.telegramOfficial}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TelegramIcon.default} alt="telegram" />
					</SocialLinks>
					<SocialLinks
						href={SocialLinkInfo.telegramAnnouncement}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TelegramIcon.default} alt="telegram" />
					</SocialLinks>
					<SocialLinks
						href={SocialLinkInfo.medium}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={MediumIcon.default} alt="medium" />
					</SocialLinks>
					<SocialLinks
						href={SocialLinkInfo.twitter}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TwitterIcon.default} alt="twitter" />
					</SocialLinks>
				</LinksContainer>
			</PageContainer>
		</>
	);
};

export default Landing;
