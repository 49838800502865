import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressBar from "@ramonak/react-progress-bar";
import {
	TopRightImg,
	RightBottomImg,
	TwitterIcon,
	InstagramIcon,
	TitokIcon,
	LikeIcon,
	CommentIcon
} from '../../images';

import {
	MainImgs,
	MainWrapper,
	MainTitle,
	HeaderTitle,
	SubTitle,
	MainContainer,
	BorderFirst,
	BorderSecond,
	MainBg,
	Container
} from "./style";

import { userEmail, userPassword } from '../../utils/constants';

require('dotenv').config();

const MainContent = () => {
	const [progress, setProgress] = useState(0);
	const [retweet, setRetweet] = useState(0);
	const [comment, setComment] = useState(0);
	const [gamfi, setGamfi] = useState(0);
	const [gamfi1, setGamfi1] = useState(0);
	const [post, setPost] = useState(0);

	console.log(userEmail, userPassword);
	console.log(progress, retweet, comment, gamfi, gamfi1, post)

	useEffect(() => {
		async function fetchData() {
			const baseURL = `https://app.mentionlytics.com/api/token?email=${userEmail}&password=${userPassword}`;
			try {
				const response = await axios
					.get(baseURL, {
						headers: {
							"X-Requested-With": "XMLHttpRequest",
						},
					});
				const { token, user_id } = response?.data;
				console.log('token', token);
				let uri = `https://app.mentionlytics.com/api/aggregation?token=${token}&commtracks=%22190424,190425%22&startDate=20211025`;
				let url = `https://app.mentionlytics.com/api/mentions?token=${token}&startDate=20210923&endDate=20211016&channels=%5B2%5D&commtracks=all`;
				const res = await axios.get(uri);
				console.log('res', res);
				const { mentions } = res?.data;
				const { totals } = res?.data;
				console.log('mentions', mentions);
				console.log('totals', totals);
				var followsCount = 0;
				// var statusCount = 0;
				var currentTotalCounts = 0;
				mentions.forEach((element) => {
					console.log('followers count', element.followers_count);
					followsCount += element.followers_count;
					// statusCount += element.statuses_count;
				})
				totals.forEach((ele) => {
					console.log('element total_count', ele.total_count);
					currentTotalCounts += ele.total_count;
				})
				console.log('total followers', followsCount)
				// console.log(statusCount);
				console.log('current total count', currentTotalCounts);
			} catch (error) {
				console.log(error)
			}
		}

		fetchData()
	}, [])

	return (
		<MainWrapper>
			<MainImgs>
				<img src={TopRightImg.default} alt="top right img" />
				<img src={RightBottomImg.default} alt="right middle img" />
			</MainImgs>
			<MainTitle>
				<HeaderTitle>
					{/* Own The Game<br /> */}
					<span>Own The Game</span>
				</HeaderTitle>
				<SubTitle>
					{/* <span>NFT-based</span> */}
					Get the tickets of spaceships which are headed to the moon.
				</SubTitle>
			</MainTitle>
			<MainContainer>
				<BorderFirst>
					<BorderSecond>
						<MainBg>
							<Container>
								<ProgressBar completed={50} />
							</Container>

							{/* <img src={TwitterIcon.default} alt="twitter icon" />
							<img src={InstagramIcon.default} alt="instagram icon" />
							<img src={TitokIcon.default} alt="titok icon" />
							<img src={LikeIcon.default} alt="like icon" />
							<img src={CommentIcon.default} alt="comment icon" /> */}
						</MainBg>
					</BorderSecond>
				</BorderFirst>
			</MainContainer>
		</MainWrapper>
	)
}

export default MainContent;