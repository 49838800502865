import styled from "styled-components";
import { SecondaryGradientButton, Theme } from "../../utils"

export const MainImgs = styled.div`
	height: 100%;

	img {
		&:first-child {
			position: absolute;
			top: 0;
			right: 0;

			@media screen and (max-width: 1500px) {
				width: 40%;
				height: 40%;
			}

			@media screen and (max-width: 650px) {
				display: none;
			}
		}

		&:nth-child(2) {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 406.85px;
			height: 488.13px;

			@media screen and (max-width: 1500px) {
				width: 15%;
				height: 20%;
			}

			@media screen and (max-width: 1135px) {
				display: none;
			}
		}
	}
`;

export const MainWrapper = styled.div`
	padding: 160px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 780px) {
		padding: 40px 0;
	}
`

export const MainTitle = styled.div`
	margin-top: 40px;
`

export const HeaderTitle = styled.div`
	font-weight: 600;
	font-size: 64px;
	line-height: 72px;
	text-align: center;
	letter-spacing: 0.01em;
	color: ${Theme.whiteText};

	span {
		background: radial-gradient(95% 95% at 36.4% 1.4%, #FFAF9B 0%, #FFE978 20.64%, rgba(255, 186, 255, 0.850701) 42.07%, #FFD5B7 62.26%, #FFFEE2 80.49%, #79A2F2 100%);
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
	}

	@media screen and (max-width: 780px) {
		font-size: 40px;
	}
`

export const SubTitle = styled.div`
	margin-top: 40px;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: ${Theme.grayText};
	text-align: center;

	span {
		font-weight: bold;
	}
`

export const MainContainer = styled.div`
	margin-top: 80px;
	border-radius: 16px;

	@media screen and (max-width: 780px) {
		margin-top: 40px;
		border-radius: 12px;
	}
`

export const BorderFirst = styled.div`
	padding: 2px;
	border-radius: 18px;
	background: conic-gradient(from 180deg at 50% 50%, #EEEEEE 0deg, #FFFEE2 14.87deg, rgba(255, 255, 255, 0.950883) 25.67deg, rgba(255, 186, 255, 0.850701) 38.19deg, rgba(255, 255, 255, 0.815523) 53deg, #79A2F2 72.26deg, #FFE978 122.18deg, rgba(255, 186, 255, 0.850701) 138.07deg, rgba(255, 255, 255, 0.596267) 145.34deg, #FFE978 162.04deg, #79A2F2 175.13deg, rgba(255, 255, 255, 0.741036) 186.54deg, #79A2F2 199.54deg, #FFE978 222.78deg, #FFFFFF 247.79deg, rgba(133, 174, 255, 0.109315) 320.65deg, #699CFF 343.05deg, #FFFFFF 348.79deg, #79A2F2 354.77deg, #FFFFFF 360deg);
`;

export const BorderSecond = styled.div`
	padding: 2px;
	background-color: #394151;
	border-radius: 18px;
	border: 2px solid #6E8A9F;
`;

export const MainBg = styled.div`
	padding: 48px;
	background: linear-gradient(180deg, #0B0E0F 0%, #1A1E23 100%);
	border-radius: 16px;
	Width: 798px;
	Height: 641px;
	border: 5px solid black;
	position: relative;

	@media screen and (max-width: 780px) {
		width: 100%;
		border-radius: 12px;
	}
`;

export const Container = styled.div`
	transform: rotate(270deg);
	position: relative;

	&>div {
		position: relative;

		&>div {
			background: linear-gradient(270.13deg, rgba(38, 54, 87, 0.6) 11.41%, rgba(7, 16, 35, 0.6) 90.96%);
			width: 417px !important;
			position: absolute;
			bottom: 0;
			right: 0;

			&>div {
				background: conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), radial-gradient(95% 95% at 36.4% 1.4%, #F7D4CB 0%, #FFFAE2 20.64%, rgba(255, 186, 255, 0.850701) 42.07%, #FFD5B7 62.26%, #FFFEE2 80.49%, #79A2F2 100%);
				background-blend-mode: screen, difference, normal;
				mix-blend-mode: normal;
			}
		}aàZ

		span {
			display: none !important;
		}
	}
`;