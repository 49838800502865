export const Color = {
	blackPrimary: "#000000",
	darkPrimary: "#333333",
	darkSecondary: "#23272E",
	whitePrimary: "#ffffff",
	grayPrimary: "#A0A5AD",
	yellowPrimary: "#826B49",
	primaryRed: "#FF4646",
};

export const Theme = {
	backgroundColor: "linear-gradient(120.36deg,#191c21 - 2.08 %,#0b1014 71.5 %)",
	blackText: Color.blackPrimary,
	whiteText: Color.whitePrimary,
	grayText: Color.grayPrimary,
	darkBg: Color.darkPrimary,
	warning: Color.yellowPrimary,
	error: Color.primaryRed,
	inputTextBg: Color.darkSecondary,
	inputTextOpacity: "0.3",
	componentBorderRadius: "12px",
	elementBorderRadius: "8px",
	modalBg: "background: rgba(0, 0, 0, 0.8);"
};
