import styled from "styled-components";
import { SecondaryGradientButton, Theme } from "../../utils"

export const PageContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 1;
	background: linear-gradient(120.36deg, #191C21 -2.08%, #0B1014 71.5%);

	@media (max-height: 768px) {
		background: linear-gradient(120.36deg, #191C21 -2.08%, #0B1014 71.5%);
	}
`;

export const HomeBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const GoToHome = styled.a`
`;

export const BottomLeft = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;

	img {
		width: 80%;
	}
`;

export const TopRight = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	img {
		width: 50%;
		height: 20%;
		float: right;
	}
`;

export const LauncherContainer = styled.div`
	width: 100%;
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		padding: 5%;
		position: relative;
		height: 100vh;

		img {
			position: absolute;
			top: 2%;
			left: 5%;
			width: 80px;
			height: 60px;
		}
	}
`;

export const ComingSoon = styled.h1`
	margin-top: 80px;
	font-family: Work Sans;
	font-weight: 600;
	font-size: 80px;
	line-height: 88px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #FFFFFF;

	@media (max-width: 768px) {
		font-size: 35px;
		font-weight: 500;
		line-height: 40px;
	}
`;

export const ComingSoonText = styled.p`
	font-family: SF Pro Text;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #a0a5ad;
	max-width: 720px;
	margin-top: 32px;
	margin-bottom: 0;

	@media (max-width: 768px) {
		font-size: 15px;
		line-height: 20px;
		margin-top: 12px;
	}
`;

export const BtnContainer = styled.div`
	margin-top: 40px;
	padding: 22px 31px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		flex-direction: column;
		margin-top: 20px;
	}
`;

export const Border = styled.div`
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ffffff -1.14deg,
    #fadd58 4.87deg,
    rgba(255, 255, 255, 0.964973) 12.61deg,
    #e6ae83 24.16deg,
    rgba(255, 255, 255, 0.949566) 29.95deg,
    rgba(255, 255, 255, 0.901443) 35.48deg,
    #ebbbf0 41.11deg,
    rgba(255, 255, 255, 0.856301) 51.73deg,
    #fadd58 59.78deg,
    rgba(255, 255, 255, 0.810395) 68.26deg,
    #cc6347 75.93deg,
    rgba(255, 255, 255, 0.765917) 88.06deg,
    #538aff 93.41deg,
    rgba(255, 255, 255, 0.7209) 96.09deg,
    #e4edff 107.05deg,
    rgba(255, 255, 255, 0.683293) 114.01deg,
    #e6ae83 120.88deg,
    #c4c4c4 122.02deg,
    rgba(255, 255, 255, 0.646412) 122.38deg,
    #103f5a 128.43deg,
    rgba(124, 200, 237, 0.579017) 134.06deg,
    rgba(39, 51, 57, 0.544723) 140.38deg,
    #242f34 146.53deg,
    rgba(33, 46, 52, 0.498667) 150.86deg,
    #23262b 316.22deg,
    #f6f08e 324.76deg,
    #b3b3b3 332.03deg,
    #ffffff 335.66deg,
    #6751f0 343.76deg,
    #e7e7e7 352.96deg,
    #ffffff 358.86deg,
    #fadd58 364.87deg
  );
  border-radius: 12px;
  padding: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  width: 201px;
  height: 52px;

  @media (max-width: 768px) {
	margin-right: 0;
  }
`;

export const YourSubmitGame = styled.a`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
    text-decoration: none;
	cursor: pointer;
    font-weight: 600;
	font-size: 16px;
	line-height: 32px;
	border-radius: 12px;
	padding: 8px 12px;
	background: linear-gradient(189deg, #2C2F35 6.83%, #0B1014 97.87%);

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const GoToContainer = styled.div`
	background: conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), radial-gradient(95% 95% at 36.4% 1.4%, #F7D4CB 0%, #FFFAE2 20.64%, rgba(255, 186, 255, 0.850701) 42.07%, #FFD5B7 62.26%, #FFFEE2 80.49%, #79A2F2 100%);
	background-blend-mode: screen, difference, normal;
	mix-blend-mode: normal;
	box-shadow: 2px 2px 0px rgba(66, 80, 92, 0.82);
	border-radius: 8px;
	cursor: pointer;
	letter-spacing: 0.01em;
	color: #263339;
	width: 201px;
	height: 48px;

	@media (max-width: 768px) {
		margin-left: 0;
		margin: 12px 0;
	}
`;

export const GoToApp = styled.a`
	text-decoration: none;
	color: #263339;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 32px;
	background: conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, #000000 51.64deg, #FFFFFF 79.77deg, #000000 141.65deg, #FFFFFF 194.15deg, #000000 254.15deg, #FFFFFF 286.03deg, #000000 331.03deg, #FFFFFF 360deg), radial-gradient(95% 95% at 36.4% 1.4%, #F7D4CB 0%, #FFFAE2 20.64%, rgba(255, 186, 255, 0.850701) 42.07%, #FFD5B7 62.26%, #FFFEE2 80.49%, #79A2F2 100%);
	background-blend-mode: screen, difference, normal;
	mix-blend-mode: normal;
	box-shadow: 2px 2px 0px rgba(66, 80, 92, 0.82);
	border-radius: 6px;
	cursor: pointer;
	border: 0;
	color: ${Theme.blackText};

	span {
		font-weight: bold;
		font-size: 16px;
		line-height: 0px;
		text-align: center;
		-webkit-letter-spacing: 0.01em;
		-moz-letter-spacing: 0.01em;
		-ms-letter-spacing: 0.01em;
		letter-spacing: 0.01em;
		display: inline-grid;
		grid-template-areas: "text";
		place-items: center;

		span {
			font-weight: 600;
			font-size: 16px;
			line-height: 23px;
			background: black;
			-webkit-background-clip: text;
			/* -webkit-text-fill-color: transparent; */
			/* -webkit-text-stroke: 0.01em rgba(0, 0, 0, 0.6); */
			/* FUn fact - letter-spacing messes with the ability of the
			gradient to cover all the text :( */
		}
	}

	:disabled {
		cursor: not-allowed;
		background: ${Theme.inputTextBg};
		box-shadow: 0px 0px;
		border-radius:${Theme.elementBorderRadius};

		span {
			color: ${Theme.darkBg};
		}
	}

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const LinksContainer = styled.div`
	margin-left: auto;
	margin-right: auto;
	bottom: 12%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 568px) {
		display: flex;
		align-items: center;
	}
`;

export const SocialLinks = styled.a`
	width: 64px;
	height: 64px;
	margin-left: 12px;
	margin-right: 12px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}

	@media (max-width: 768px) {
		width: 10%;
		height: 10%;
		margin-right: 12px;
	}
`;
