import React from 'react';

import { FooterContainer, FooterTitle, IconContainer, Icon, LinkText } from "./style";
import { TwitterIcon, TelegramIcon, MediumIcon } from "../../images";
import { SocialLinkInfo } from "../../utils";

import { } from './style'

const Footer = () => {
	return (
		<FooterContainer>
			<FooterTitle>
				Follow us on:
			</FooterTitle>
			<IconContainer>
				<Icon>
					<a
						href={SocialLinkInfo.twitter}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TwitterIcon.default} alt="twitter" />

						<LinkText>Twitter</LinkText>
					</a>
				</Icon>
				<Icon>
					<a
						href={SocialLinkInfo.telegramOfficial}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TelegramIcon.default} alt="telegram" />

						<LinkText>Official Telegram</LinkText>
					</a>
				</Icon>
				<Icon>
					<a
						href={SocialLinkInfo.telegramAnnouncement}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TelegramIcon.default} alt="telegram" />

						<LinkText>Official<br />Annoucments</LinkText>
					</a>
				</Icon>
				<Icon>
					<a
						href={SocialLinkInfo.medium}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={MediumIcon.default} alt="medium" />

						<LinkText>Medium</LinkText>
					</a>
				</Icon>
				{/* <Icon>
					<a
						href={SocialLinkInfo.coinGecko}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TwitterIcon.default} alt="twitter" />

						<LinkText>CoinGecko</LinkText>
					</a>
				</Icon>
				<Icon>
					<a
						href={SocialLinkInfo.coinMarketCap}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={TelegramIcon.default} alt="telegram" />

						<LinkText>CoinMarketCap</LinkText>
					</a>
				</Icon> */}
			</IconContainer>
		</FooterContainer>
	)
}

export default Footer;