// import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	HeaderWrapper,
	HeaderContainer,
	LeftContainer,
	RoyaLogo,
	RightContainer,
	ListYourProjects,
	ConnectWalletBorder,
	ConnectWallet,
	MenuIconEl
} from "./style";
import { RoyaLogoIcon, MenuIcon } from "../../images";

const Header = () => {

	return (
		<HeaderWrapper>
			<HeaderContainer>
				<LeftContainer>
					<Link
						to='/'
					>
						<img src={RoyaLogoIcon.default} alt="Roya logo" />
					</Link>
				</LeftContainer>
				{/* <RightContainer>
					<ListYourProjects>
						List your project
					</ListYourProjects>
					<ConnectWalletBorder>
						<ConnectWallet>
							Connect Wallet
						</ConnectWallet>
					</ConnectWalletBorder>
					<MenuIconEl>
						<img src={MenuIcon.default} alt="Menu icon" />
					</MenuIconEl>
				</RightContainer> */}
			</HeaderContainer>
		</HeaderWrapper>
	);
};

export default Header;
