//@ts-ignore
const Images = require.context("./images", true);

export const RoyaLogoIcon = Images("./Logo.png");

export const MenuIcon = Images("./menu-icon.png");

export const TwitterIcon = Images("./icon-twitter.png");

export const TelegramIcon = Images("./icon-telegram.png");

export const MediumIcon = Images("./icon-medium.png");

export const InstagramIcon = Images("./icon-instagram.png");

export const TitokIcon = Images("./icon-tiktok.png");

export const LikeIcon = Images("./icon-like.png");

export const CommentIcon = Images("./icon-comment.png");

export const BottomLeftBg = Images("./bottom-left.png");

export const TopRightImg = Images("./top-right.png");

export const LeftMiddleImg = Images("./left-middle.png")

export const RightMiddleImg = Images("./right-middle.png")

export const RightBottomImg = Images("./right-bottom.png")

export const VideoFile = Images("./animation-bg.webm");

export const CloseIcon = Images("./close-icon.png");